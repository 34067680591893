import React, { useEffect, useState } from "react";
import * as yup from "yup";
import CheckPhoneModal from "./CheckPhoneModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ButtonPrimary from "component/Common/ButtonPrimary";
import { observer } from "mobx-react-lite";
import BookingStore from "stores/BookingStore";
import { getOTPCode } from "services/HttpServices";
import DataStore from "stores/DataStore";
import ReCAPTCHA from "react-google-recaptcha";
import ClinicsStore from "stores/ClinicsStore";
import { useNavigate } from "react-router-dom";
import { GAEvent, GAEventTypes } from "Utils/GoogleAnalytics";
import { useScrollToTop } from "hooks/useSrollToTop";
import CommonModal from "component/Common/CommonModal";
import ButtonSecondary from "component/Common/ButtonSecondary";

const validateForm = (formValues) => {
  const formSchema = yup.object({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email("Email must be a valid email address").required(),
    isPhoneVerified: yup.bool().required(),
    phone_number: yup.string().when("isPhoneVerified", {
      is: false,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  try {
    if (
      formSchema.validateSync(formValues, {
        strict: true,
        abortEarly: false,
      })
    ) {
      return [];
    }
  } catch (e) {
    return Object.values(e.inner).reduce(
      (carry, error) => ({
        ...carry,
        [error.path]: error.message,
      }),
      {},
    );
  }
};

const StepOne = () => {
  useScrollToTop();
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [validateCaptcha, setValidateCaptcha] = useState(false);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [isPreferencesPopupOpen, setIsPreferencesPopupOpen] = useState(false);

  const { patientInfo } = DataStore;

  const {
    setStep,
    contactInfo,
    setContactInfo,
    bookingInfoError,
    setBookingInfoError,
    numberVerified,
    consentSms,
    consentEmail,
    doNotConsent,
    setEmailConsent,
    setSmsConsent,
    setDoNotConsent,
    isConsentActive,
  } = BookingStore;

  const { selectedClinic } = ClinicsStore;
  const navigate = useNavigate();

  const handleInput = (event) => {
    const inputErrors = bookingInfoError;
    setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
    delete inputErrors[event.target.name];
    setBookingInfoError({ ...bookingInfoError, inputErrors });
  };

  const verifyMobile = (isDoNotConsent) => {
    if (isDoNotConsent) {
      return setIsConsentModalOpen(true);
    }

    const form = {
      firstname: contactInfo.firstname?.trim(),
      lastname: contactInfo.lastname?.trim(),
      email: contactInfo.email,
      phone_number: contactInfo.phone_number,
    };

    const errors = validateForm({
      ...form,
      isPhoneVerified: Boolean(numberVerified),
    });

    if (Object.keys(errors).length > 0) {
      return setBookingInfoError(errors);
    }

    if (numberVerified && numberVerified === form.phone_number) {
      return setStep(2);
    }

    getOTPCode(form, setShowPhoneModal);
  };

  useEffect(() => {
    if (selectedClinic && !Boolean(selectedClinic?.is_available_online)) {
      navigate("/");
    }
  }, [selectedClinic]);

  useEffect(() => {
    setContactInfo({
      ...contactInfo,
      country: patientInfo.phone_number
        ? "us"
        : selectedClinic?.country.toLowerCase(),
    });

    if (patientInfo.email) {
      setContactInfo({
        ...contactInfo,
        firstname: patientInfo.firstname,
        lastname: patientInfo.lastname,
        email: patientInfo.email,
        phone_number: patientInfo.phoneNumber,
      });
    }
  }, [patientInfo.email, selectedClinic]);

  useEffect(() => {
    GAEvent(GAEventTypes.BOOKING_STARTED);
  }, []);

  const setPhoneVerificationStep = (stepNumber) => {
    if (stepNumber === 2) {
      GAEvent(GAEventTypes.PHONE_VERIFIED);
    }
    setStep(stepNumber);
  };

  return (
    <>
      <h4 className="mb-6 text-[16px]">Enter Your Contact Info</h4>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
        <div className="flex flex-col gap-[6px]">
          <label htmlFor="first_name">First Name </label>
          <input
            className={`border-[1px] border-solid p-2 rounded-lg ${Object.keys(bookingInfoError).includes("firstname")
                ? "border-red-500"
                : "border-[#D0D5DD]"
              }`}
            value={contactInfo.firstname}
            onChange={(event) => handleInput(event)}
            type="text"
            name="firstname"
            placeholder="Enter First Name"
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label htmlFor="last_name">Last Name </label>
          <input
            className={`border-[1px] border-solid p-2 rounded-lg ${Object.keys(bookingInfoError).includes("lastname")
                ? "border-[red]"
                : "border-[#D0D5DD]"
              }`}
            value={contactInfo.lastname}
            onChange={(event) => handleInput(event)}
            type="text"
            name="lastname"
            placeholder="Enter Last Name"
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label htmlFor="email"> Email </label>
          <input
            className={`border-[1px] border-solid p-2 rounded-lg ${Object.keys(bookingInfoError).includes("email")
                ? "border-[red]"
                : "border-[#D0D5DD]"
              } `}
            value={contactInfo.email}
            onChange={(event) => handleInput(event)}
            type="text"
            name="email"
            placeholder="Enter Email"
          />
        </div>
        <div className="flex flex-col gap-[6px]">
          <label htmlFor="phone">Phone Number </label>
          <PhoneInput
            inputClass={`!w-full !py-2 !text-md !h-[42px] !rounded-lg ${Object.keys(bookingInfoError).includes("phone_number")
                ? "!border-[red]"
                : "!border-[#D0D5DD]"
              }`}
            value={contactInfo.phone_number}
            country={contactInfo.country}
            inputProps={{ name: "phone_number" }}
            countryCodeEditable={false}
            preferredCountries={["us"]}
            enableSearch={true}
            onChange={(phone) =>
              setContactInfo({ ...contactInfo, phone_number: phone })
            }
          />
        </div>
      </div>
      <div>
        <label htmlFor="appointment_notes">
          Appointment Notes{" "}
          <span className="text-[14px] text-[#94A3B8]">(optional)</span>
        </label>
        <textarea
          className="w-full border-[1px] border-solid p-2 rounded-lg resize-none"
          name="appointment_notes"
          value={contactInfo.appointment_notes}
          onChange={(event) => handleInput(event)}
        ></textarea>
      </div>
      <span className="text-[14px] text-[#94A3B8]">
        Your name, email and phone number are used to send emails and SMS
        messages regarding your appointment status. Please provide your consent
        to receive these messages.
      </span>
      <div
        className="flex items-center gap-2 text-[#64748B] my-2"
        onClick={() => setSmsConsent(!consentSms)}
      >
        <div className="flex flex-col justify-start max-sm:h-[34px]">
          <input type="checkbox" name="consentSms" checked={consentSms} />
        </div>
        <label htmlFor="agreeMembership" className="text-[14px] text-[#94A3B8]">
          I consent to receiving SMS messages regarding my appointment.
        </label>
      </div>
      <div
        className="flex items-center gap-2 text-[#64748B] my-2"
        onClick={() => setEmailConsent(!consentEmail)}
      >
        <div className="flex flex-col justify-start max-sm:h-[34px]">
          <input type="checkbox" name="consentEmail" checked={consentEmail} />
        </div>
        <label htmlFor="agreeMembership" className="text-[14px] text-[#94A3B8]">
          I consent to receiving Email messages regarding my appointment.
        </label>
      </div>
      <div
        className="flex items-center gap-2 text-[#64748B] mb-2"
        onClick={() => setDoNotConsent(!doNotConsent)}
      >
        <input type="checkbox" name="doNotConsent" checked={doNotConsent} />
        <label htmlFor="agreeMembership" className="text-[14px] text-[#94A3B8]">
          I do not consent.
        </label>
      </div>
      <div className="mt-4">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          onChange={() => setValidateCaptcha(true)}
          onExpired={() => setValidateCaptcha(false)}
        />
      </div>
      <div className="mt-4">
        <ButtonPrimary
          text="Verify Mobile Number"
          onClick={() => {
            if (!isConsentActive) {
              return setIsPreferencesPopupOpen(true);
            }
            verifyMobile(doNotConsent);
          }}
          disabled={!validateCaptcha}
        />
      </div>
      {showPhoneModal && (
        <CheckPhoneModal
          setShowPhoneModal={setShowPhoneModal}
          setStep={setPhoneVerificationStep}
        />
      )}
      {isConsentModalOpen && (
        <CommonModal>
          <h5 className="pb-2">
            Important Information About Your Contact Preferences
          </h5>
          <p>
            Please be advised, by opting out of receiving email and/or SMS
            notifications, you will not receive key updates, confirmations and
            reminders for your appointment. In order to complete registration
            for our Patient Portal, you must consent to SMS and Email
            communications. You can revoke your consent at any time.
          </p>
          <div className="flex flex-end gap-2 pt-3">
            <ButtonPrimary
              text="Continue"
              onClick={() => {
                setIsConsentModalOpen(false);
                verifyMobile(false);
              }}
            />
            <ButtonSecondary
              text="Cancel"
              onClick={() => setIsConsentModalOpen(false)}
            />
          </div>
        </CommonModal>
      )}
      {isPreferencesPopupOpen && (
        <CommonModal>
          <p>Please select your contact preferences</p>
          <ButtonPrimary
            text="Ok"
            onClick={() => {
              setIsPreferencesPopupOpen(false);
            }}
          />
        </CommonModal>
      )}
    </>
  );
};

export default observer(StepOne);
